.doc-item-content {
  width: calc(100% / 6);
  border-top: 1px solid #cccccc;
  display: flex;
  justify-content: center;
  padding: 10px 0;
  margin-bottom: 10px;
  transition: 0.2s all;
}
.doc-item-list {
  width: 100%;
  border-top: 1px solid #cccccc;
  transition: 0.2s all;
}
.doc-item-content:hover {
  background: #5e959c;
}
.doc-item-content .doc-item-box {
  width: 160px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.doc-item-list .doc-item-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}
.doc-item-coverpage {
  width: 160px;
  height: 160px;
  background: #d8d8d8;
  overflow: hidden;
  margin-bottom: 5px;
}
.doc-item-coverpage img {
  width: 100%;
  object-fit: cover;
  object-position: top center;
}
.doc-item-content .doc-item-description {
  width: 100%;
  height: 90px;
  overflow: hidden;
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 10px;
}
.doc-item-list .doc-item-description {
  width: calc(100% - 100px);
}
.doc-item-button {
  width: 80px;
  height: 40px;
  border-radius: 5px;
  background: #3b4752;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  transition: 0.2s all;
}
.doc-item-button:hover {
  background: #346c91;
  color: #ffffff;
  text-decoration: none;
}
@media screen and (max-width: 360px) {
  .doc-item-coverpage {
    width: 100px;
    height: 120px;
    margin: 0 auto;
  }
  .doc-item-description {
    padding: 0 8px 5px;
  }
}
