@font-face {
  font-family: "ConduitITC";
  src: url("fonts/ConduitITC.eot?#iefix");
  src: url("fonts/ConduitITC.eot?#iefix") format("embedded-opentype"),
    url("fonts/ConduitITC.woff") format("woff"),
    url("fonts/ConduitITC.ttf") format("truetype"),
    url("fonts/ConduitITC.svg#ConduitITC") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ConduitITC-bold";
  src: url("fonts/ConduitITC-Bold.eot?#iefix");
  src: url("fonts/ConduitITC-Bold.eot?#iefix") format("embedded-opentype"),
    url("fonts/ConduitITC-Bold.woff") format("woff"),
    url("fonts/ConduitITC-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Oswald-Medium";
  src: url("fonts/Oswald-Medium.eot?#iefix") format("embedded-opentype"),
    url("fonts/Oswald-Medium.woff") format("woff"),
    url("fonts/Oswald-Medium.ttf") format("truetype"),
    url("fonts/Oswald-Medium.svg#Oswald-Medium") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url("fonts/Montserrat-Medium.eot?#iefix") format("embedded-opentype"),
    url("fonts/Montserrat-Medium.woff") format("woff"),
    url("fonts/Montserrat-Medium.ttf") format("truetype"),
    url("fonts/Montserrat-Medium.svg#Montserrat-Medium") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Lato-Regular";
  src: url("fonts/Lato-Regular.eot?#iefix") format("embedded-opentype"),
    url("fonts/Lato-Regular.woff") format("woff"),
    url("fonts/Lato-Regular.ttf") format("truetype"),
    url("fonts/Lato-Regular.svg#Lato-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ConduitITCStd";
  src: url("fonts/ConduitITCStd-Regular.eot");
  src: url("fonts/ConduitITCStd-Regular.eot?#iefix") format("embedded-opentype"),
    url("fonts/ConduitITCStd-Regular.woff2") format("woff2"),
    url("fonts/ConduitITCStd-Regular.woff") format("woff"),
    url("fonts/ConduitITCStd-Regular.ttf") format("truetype"),
    url("fonts/ConduitITCStd-Regular.svg#ConduitITCStd-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}
