.ButtonGraph {
  width: 100%;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  border: 0;
  font-size: 18px;
  position: relative;
}
.ButtonGraph i {
  position: absolute;
  right: 10px;
  top: 7px;
}
