.contact-bg,
.contact-form {
  margin-bottom: 30px;
}
.contact-form,
.contact-form input,
.contact-form textarea,
.contact-form button {
  font-size: 14pt !important;
}
.contact-form label {
  margin: 10px 0 5px;
}
.contact-form input[type="checkbox"] {
  margin-right: 5px;
}
.contact-social-networks {
  width: 100%;
  text-align: center;
  margin: 10px 0;
}
.contact-social-networks h3 {
  font-size: 20px;
  margin-bottom: 5px;
  margin-top: 20px;
}
.contact-social {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.contact-social a {
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3b4752;
  transition: 0.2s all;
  border-right: 1px solid #3b4752;
  font-size: 20px;
}
.contact-social a:nth-child(1) {
  margin-left: 0;
}
.contact-social a:hover {
  color: #346c91;
  text-decoration: none;
  opacity: 0.75;
}
