.InitiativeFilters_filters {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  margin-top: 40px;
}
.InitiativeFilters_filters-list {
  margin-bottom: 30px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
