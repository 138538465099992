.filter-select-box {
  width: calc(100% / 6 - 6px);
  height: 35px;
  margin: 0 3px;
  font-size: 12px;
  line-height: 15px;
  position: relative;
  border: 1px solid #cccccc;
  border-radius: 5px;
  transition: 0.2s all;
  cursor: pointer;
}
.filter-select-box:hover {
  border: 1px solid #7a7a7a;
}
.filter-select-header {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.filter-select-box span {
  color: #8c8536;
}
.filter-select-box.yellow span {
  color: #f59c00;
}
.filter-select-header span {
  font-size: 20px;
  margin-left: 5px;
  margin-right: 5px;
}
.filter-select-title {
  transition: 0.2s all;
  padding: 0 5px;
  word-break: break-all;
  font-size: 12pt;
}
.filter-select-content {
  width: 100%;
  height: auto;
  max-height: 350px;
  border: 1px solid #7a7a7a;
  background: rgba(255, 255, 255, 0.9);
  left: 0;
  top: 33px;
  position: absolute;
  padding: 0 8px;
  font-size: 11px;
  line-height: 14px;
  visibility: hidden;
  opacity: 0;
  transition: 0.2s all;
  overflow: auto;
  z-index: 5;
}
.filter-select-box:hover .filter-select-content {
  visibility: visible;
  opacity: 1;
}
.filter-select-box:focus .filter-select-content {
  visibility: visible;
  opacity: 1;
}
.filter-select-content button {
  width: 100%;
  background: transparent;
  border: transparent;
  border-radius: 0;
  text-align: left;
  border-bottom: 1px solid #8c8536;
  transition: 0.2s all;
  padding: 5px 0;
  font-size: 10pt;
  line-height: 12pt;
}
.filter-select-box.yellow .filter-select-content button {
  border-bottom: 1px solid #f59c00;
}
.filter-select-content button:hover {
  color: #8c8536;
}
.filter-select-box.yellow .filter-select-content button:hover {
  color: #f59c00;
}
.filter-select-content button:last-child {
  border-bottom: transparent;
}
.filter-select-button {
  text-transform: capitalize;
}
.filter-select-content button:last-child {
  border-bottom: 0 !important;
}
@media screen and (max-width: 769px) {
  .filter-select-box {
    width: calc(100% / 3 - 6px);
    margin: 2px auto;
  }
}
@media screen and (max-width: 450px) {
  .filter-select-box {
    width: 90%;
  }
}
