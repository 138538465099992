.ecosystem-stage-content {
  width: 100%;
  padding: 10px;
  display: flex;
  border: 1px solid #cccccc;
  margin-bottom: 25px;
  border-radius: 3px;
}
.ecosystem-stage-content .ecosystem-stage-row {
  width: calc(100% / 4);
}
.ecosystem-stage-row:nth-child(1),
.ecosystem-stage-row:nth-child(2),
.ecosystem-stage-row:nth-child(3) {
  border-right: 1px solid #4a4a49;
}
.ecosystem-stage-title {
  width: 100%;
  height: 44px;
  border-bottom: 1px solid #4a4a49;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  padding: 0 8px;
}
.ecosystem-stage-title-txt {
  width: calc(100% - 32px);
}
.ecosystem-stage-icon img {
  width: 25px;
  height: 25px;
}
.ecosystem-stage-square {
  height: 138px;
  background: #ffffff;
  border: 1px solid #cccccc;
  margin: 8px 8px 0 8px;
  padding: 5px;
}
.ecosystem-stage-square-title {
  width: 100%;
  height: 40px;
  line-height: 18px;
}
.ecosystem-stage-square-number {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f59c00;
  font-size: 60px;
  line-height: 0;
}
@media screen and (max-width: 1024px) {
  .ecosystem-stage-title {
    font-size: 13px;
  }
  .button-yellow {
    width: 60px !important;
    font-size: 11px;
  }
  .ecosystem-population-bars {
    width: 100% !important;
  }
}
@media screen and (max-width: 765px) {
  .ecosystem-stage-content {
    flex-wrap: wrap;
  }
  .ecosystem-stage-row {
    width: 50% !important;
  }
  .ecosystem-stage-row:nth-child(1),
  .ecosystem-stage-row:nth-child(2) {
    padding-bottom: 20px;
    border-bottom: 1px solid #4a4a49;
  }
  .ecosystem-stage-row:nth-child(2) {
    border-right: 0;
  }
}
@media screen and (max-width: 550px) {
  .ecosystems-box-count {
    flex-wrap: wrap;
  }
  .ecosystems-sections,
  .ecosystems-count {
    width: 100% !important;
    margin-right: 0 !important;
  }
}
