.box-population {
  width: calc(100% / 4);
  padding: 0;
  background: none;
  border: 1px solid #ffffff;
  position: relative;
  padding: 20px 0 0;
}
.box-population-title {
  width: 100%;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  padding: 0 10px;
  margin-bottom: 5px;
}
.box-population-number {
  width: 100%;
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 40px;
}
.box-population-more {
  position: absolute;
  right: 5px;
  bottom: 5px;
  width: 20px;
  height: 20px;
  color: #ffffff;
  font-size: 10px;
  background: #5f3c7a;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.bg-purple-0 {
  background: #5f3c7a;
}
.bg-purple-1 {
  background: #6e4075;
}
.bg-purple-2 {
  background: #7c4370;
}
.bg-purple-3 {
  background: #8e486a;
}
.bg-purple-4 {
  background: #9b4d65;
}
.bg-purple-5 {
  background: #a35d70;
}
.bg-purple-6 {
  background: #ac6e7e;
}
.bg-purple-7 {
  background: #b5808c;
}
