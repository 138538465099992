.tutorial-container {
  width: 100%;
  position: relative;
  padding-top: 40px;
}
.tutorial-ecosystem-close {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: #ffffff;
  color: #5f3c7a;
  font-size: 30px;
  line-height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.2s all;
  position: absolute;
  top: 20px;
  right: 0;
}
.tutorial-ecosystem-close:hover {
  opacity: 0.75;
}
.tutorial-ecosystem-content {
  width: 100%;
  height: 100%;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  background: rgba(245, 156, 0, 0.88);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all;
}
.tutorial-ecosystem-content.active {
  visibility: visible;
  opacity: 1;
}
.tutorial-ecosystem-header {
  display: flex;
  justify-content: center;
  padding-top: 50px;
}
.tutorial-ecosystem-1-content,
.tutorial-ecosystem-2,
.tutorial-ecosystem-3 {
  display: flex;
}
.tutorial-ecosystem-1 {
  width: 350px;
}
.tutorial-ecosystem-number {
  width: 40px;
  font-size: 35px;
  color: #5f3c7a;
}
.tutorial-ecosystem-1 img {
  width: 140px;
  margin-bottom: 10px;
}
.tutorial-ecosystem-1 p,
.tutorial-ecosystem-2-content p,
.tutorial-ecosystem-2-description,
.tutorial-ecosystem-3-description,
.tutorial-ecosystem-3-content p {
  color: #ffffff;
  line-height: 18px;
}
.tutorial-ecosystem-2 {
  width: 800px;
}
.tutorial-ecosystem-2-content {
  position: relative;
  display: flex;
  margin-bottom: 10px;
}
.tutorial-ecosystem-2 .tutorial-ecosystem-img,
.tutorial-ecosystem-2-description {
  width: calc(100% - 265px);
  margin-left: 10px;
}
.tutorial-ecosystem-2-content .tutorial-ecosystem-arrow {
  width: 90px;
  position: absolute;
  top: -6px;
  left: 60%;
}
.tutorial-ecosystem-2-content p {
  width: 200px;
  margin-left: 10px;
  padding-top: 40px;
}
.tutorial-ecosystem-3 {
  width: 800px;
  margin: 0 auto 40px;
}
.tutorial-ecosystem-3-content {
  display: flex;
  position: relative;
}
.tutorial-ecosystem-3-content .tutorial-ecosystem-img,
.tutorial-ecosystem-3-description {
  width: calc(100% - 280px);
  margin-left: 10px;
}
.tutorial-ecosystem-3-content .tutorial-ecosystem-img {
  margin-top: 40px;
  margin-bottom: 10px;
}
.tutorial-ecosystem-3-content .tutorial-ecosystem-arrow {
  width: 90px;
  position: absolute;
  left: 63%;
  top: 20px;
}
.tutorial-ecosystem-3-content p {
  width: 200px;
  padding-top: 60px;
  margin-left: 10px;
}
@media screen and (max-width: 769px) {
  .tutorial-ecosystem-header {
    flex-direction: column;
  }
  .tutorial-ecosystem-1,
  .tutorial-ecosystem-2 {
    margin-bottom: 30px;
  }
  .tutorial-ecosystem-1,
  .tutorial-ecosystem-2,
  .tutorial-ecosystem-3 {
    width: calc(100% - 40px);
  }
  .tutorial-ecosystem-2 .tutorial-ecosystem-img,
  .tutorial-ecosystem-2-description,
  .tutorial-ecosystem-3-content .tutorial-ecosystem-img {
    width: calc(100% - 210px);
  }
  .tutorial-ecosystem-2-content .tutorial-ecosystem-arrow {
    left: 64%;
  }
}
@media screen and (max-width: 700px) {
  .tutorial-ecosystem-arrow {
    display: none;
  }
  .tutorial-ecosystem-1 img,
  .tutorial-ecosystem-2 img,
  .tutorial-ecosystem-3 img {
    margin: 0 auto 10px;
  }
  .tutorial-ecosystem-2 img {
    width: calc(100% - 10px) !important;
  }
  .tutorial-ecosystem-1 img,
  .tutorial-ecosystem-3 img,
  .tutorial-ecosystem-1 p,
  .tutorial-ecosystem-2 p,
  .tutorial-ecosystem-3 p {
    width: calc(100% - 50px) !important;
  }
  .tutorial-ecosystem-1 p,
  .tutorial-ecosystem-2 p,
  .tutorial-ecosystem-3 p {
    padding: 0;
  }
  .tutorial-ecosystem-2-content,
  .tutorial-ecosystem-3-content {
    flex-wrap: wrap;
  }
  .tutorial-ecosystem-3 {
    width: 100%;
  }
}
.tutorial-ecosystem-image {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 50px 0;
}
.tutorial-ecosystem-image img {
  width: 100%;
  max-width: 700px;
}
