.about-bg {
  width: 100%;
  margin-bottom: 40px;
}
.about-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.about-box-circle {
  width: 75px;
  height: 75px;
  border: 3px solid #64a1a2;
  padding: 8px;
  border-radius: 50%;
  margin-right: 10px;
}
.about-circle-title {
  width: 100%;
  height: 100%;
  background: #64a1a2;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 35px;
}
.about-text {
  margin-bottom: 30px;
  font-size: 14pt;
}
.about-text ul {
  padding-left: 20px;
  list-style: disc;
}
