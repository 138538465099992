.modal-loading {
  width: 100%;
  height: 100%;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  transition: 1s all;
}
.modal-loading.hidden {
  opacity: 0;
  visibility: hidden;
}
.modal-loading.visible {
  opacity: 1;
  visibility: visible;
}
.modal-loading img {
  width: 80px;
}
.modal-loading-txt {
  color: #5E959C;
  font-size: 25px;
  text-align: center;
}
.modal-loaded {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 20px;
  color:#5e959c;
}
.modal-loaded img {
  width: 80px;
}