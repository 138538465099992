.boxStage-subtitle:hover .tooltipIndicator_container {
  opacity: 1;
  visibility: visible;
}
.tooltipIndicator_container {
  width: 350px;
  left: 0;
  bottom: 10px;
  position: absolute;
  z-index: 4;
  opacity: 0;
  visibility: hidden;
  transition: .2s all;
}
.tooltipIndicator_content {
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  background-color: rgba(215, 215,215, .95);
  color: #000000;
}
.tooltipIndicator_angle {
  width: 0;
  height: 0;
  border-top: 15px solid rgba(215, 215,215, .95);
  border-right: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid transparent;
  margin-left: 20px;
}