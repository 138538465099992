.territory-content {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
}
.territory-stages-container {
  width: 310px;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}
.territory-stages {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  border: 1px solid #cccccc;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.territory-map {
  width: calc(100% - 660px);
  position: relative;
}
.territory-population-container {
  width: 100%;
  border-bottom: 1px solid #cccccc;
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.territory-population-title {
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}
.territory-population {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 20px;
  border: 1px solid #cccccc;
  border-radius: 3px;
}
.national-territory > .territory-name {
  color: #4a4a49;
}
@media screen and (max-width: 1000px) {
  .territory-content {
    flex-wrap: wrap;
  }
  .territory-map {
    width: 100%;
  }
  .territory-population {
    justify-content: center;
  }
  .box-population {
    width: 150px;
  }
  .territory-stages-container {
    width: calc(50% - 10px);
    margin-right: 0;
  }
}
@media screen and (max-width: 765px) {
  .territory-stages-container {
    width: 100%;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 360px) {
  .territory-name {
    font-size: 13px;
    line-height: 14px;
  }
}
