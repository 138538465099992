.InitiativeList_item {
  height: 100%;
  padding-bottom: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #cdcdcd;
  font-size: 14pt;
}
.InitiativeList_icons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.InitiativeList_title {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.InitiativeList_title h2 {
  width: calc(100% - 75px);
  font-size: 16pt;
  margin: 10px 0 0;
}
.InitiativeList_link {
  width: 60px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border: 1px solid #949494;
  margin-top: 10px;
  border-radius: 5px;
  color: #f59c00;
  transition: 0.2s all;
  margin-bottom: 5px;
}
.InitiativeList_link span {
  margin-right: 5px;
}
.InitiativeList_link:hover {
  background-color: #f59c00;
  color: #ffffff;
  text-decoration: none;
}
.InitiativeList_detail {
  display: flex;
  justify-content: center;
  align-items: center;
}
.InitiativeList_detail a {
  padding: 8px 20px;
  font-size: 18px;
  border-radius: 10px;
  background-color: #f59c00;
  color: #ffffff;
  margin-top: 10px;
  transition: 0.2s all;
  font-weight: bold;
}
.InitiativeList_detail a:hover {
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.5);
  color: #ffffff;
  text-decoration: none;
}
