.boxSearch-content {
  width: 90%;
  max-width: 600px;
  margin: 20px auto 15px;
  position: relative;
}
.boxSearch {
  width: 100%;
  border: 1px solid #949494;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.boxSearch span {
  font-size: 24px;
  color: #33414f;
}
.boxSearch input {
  height: 50px;
  border: 0;
  color: #33414f;
  font-size: 20px;
  background: none;
}
.boxSearch input::placeholder {
  color: #33414f;
  opacity: 1;
}
.boxSearch input:-ms-input-placeholder {
  color: #33414f;
}
.boxSearch input::-ms-input-placeholder {
  color: #33414f;
}
.boxSearch button,
.boxSearch div {
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 0;
  background: none;
  transition: 0.2s all;
  border-radius: 0 4px 4px 0;
}
.boxSearch button:hover {
  background: #33414f;
}
.boxSearch button:hover span {
  color: #ffffff;
}
.boxSearch button:focus {
  outline: none;
}
.boxSearch-input-menu {
  width: calc(100% - 100px);
}
.boxSearch-input-all {
  width: calc(100% - 50px);
}
.boxSearch-keywords {
  display: none;
  width: 100%;
  max-height: 200px;
  border: 1px solid #949494;
  border-radius: 5px;
  overflow: auto;
  top: 100%;
  position: absolute;
  z-index: 1;
}
.boxSearch-content:hover > .boxSearch-keywords {
  display: flex;
  flex-direction: column;
}
.boxSearch-keywords button {
  border: 0;
  border-bottom: 1px solid #949494;
  background-color: #f3f2f2;
  font-size: 13pt;
  padding: 3px 10px;
}
.boxSearch-keywords button:last-child {
  border-bottom: 0;
}
