.InitiativeIcon_content {
  width: 40px;
  height: 40px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.InitiativeIcon_content.left {
  justify-content: flex-start;
}
.InitiativeIcon_content img {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.InitiativeIcon_tootip {
  width: 150px;
  padding: 5px 10px;
  background-color: #000000;
  color: #ffffff;
  position: absolute;
  bottom: 40px;
  z-index: 1;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all;
  text-align: center;
}
.InitiativeIcon_content.left .InitiativeIcon_tootip {
  width: 180px;
  padding: 3px 10px;
  bottom: 40px;
}
.InitiativeIcon_tootip-arrow {
  width: 8px;
  display: block;
  margin: auto;
  left: 0;
  right: 0;
  border-left: 6px solid transparent;
  border-top: 8px solid black;
  border-right: 6px solid transparent;
  position: absolute;
  bottom: -8px;
}
.InitiativeIcon_content.left .InitiativeIcon_tootip-arrow {
  right: initial;
  left: 10px;
}
.InitiativeIcon_content:hover > .InitiativeIcon_tootip {
  opacity: 1;
  visibility: visible;
}
.InitiativeList_actors {
  font-size: 14pt;
  color: #949494;
}
.InitiativeList_actors span::after {
  content: ", ";
}
.InitiativeList_actors span:last-child::after {
  content: "";
}
