.ReactPDFViewer_content {
  height: 620px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
}
.ReactPDFViewer_toolbar {
  align-items: center;
  background-color: #eeeeee;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  padding: 4px;
}
.ReactPDFViewer_viewer {
  flex: 1;
  overflow: auto;
}
@media screen and (max-width: 600px) {
  .rpv-toolbar-right {
    display: none !important;
  }
}
