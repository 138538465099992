* {
  font-family: "ConduitITC";
  box-sizing: border-box;
}
html,
body {
  color: #4a4a49;
}
html.no-scroll,
body.no-scroll {
  overflow: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6,
strong,
b {
  font-family: "ConduitITC-bold";
}
p {
  font-size: 14pt;
}
.bg-purple {
  background: #5f3c7a;
}
.bg-raisin-purple {
  background: #6e4075;
}
.bg-plum-purple {
  background: #7c4370;
}
.bg-mulberry-purple {
  background: #8e486a;
}
.bg-onion-purple {
  background: #9b4d65;
}
.bg-orchid-purple {
  background: #a35d70;
}
.bg-grape-purple {
  background: #ac6e7e;
}
.bg-opal-purple {
  background: #b5808c;
}
.gray {
  background: #cccccc;
}
.bg-coral {
  background: #d77561;
}
.bg-yellow {
  background: #f59c00;
}
.bg-green {
  background: #8c8536;
}
.bg-blue {
  background: #5e959c;
}
.bg-gray-gradient {
  background: #fbfcfc; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #fbfcfc 8%,
    #e6e6e6 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #fbfcfc 8%,
    #e6e6e6 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #fbfcfc 8%,
    #e6e6e6 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fbfcfc', endColorstr='#e6e6e6',GradientType=0 ); /* IE6-9 */
}
.bg-section {
  width: 100%;
  height: 300px;
  background-size: cover;
  position: relative;
  background-position: left center;
}
.title-section {
  max-width: 500px;
  position: absolute;
  left: 20px;
  bottom: 5px;
  color: #ffffff;
  font-size: 80px;
  line-height: 80px;
}
.description-section {
  color: #ffffff;
  padding: 15px 20px;
  margin-bottom: 20px;
}
.description-section p {
  margin-bottom: 0;
}
.description-section span {
  font-size: 16pt !important;
}
.subtitle-section {
  font-size: 18px;
  line-height: 22px;
  padding-left: 10px;
}
.subtitle-section span {
  color: #4a4a49;
  font-size: 16px;
  opacity: 0.45;
}
button:focus {
  outline: none;
}
.text-center {
  text-align: center;
}
.button-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
}
.button-modal-circle {
  width: 46px;
  height: 40px;
  background: url(../images/bg-circle-02.png) center no-repeat;
  background-size: 100% 100%;
  color: #ffffff;
  font-size: 30px;
  padding-left: 5px;
  position: relative;
  z-index: 1;
}
.button-modal-text {
  height: 30px;
  border-radius: 15px;
  font-size: 20px;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  background: #80bad7;
  color: #ffffff;
  display: flex;
  align-items: center;
  padding-left: 40px;
  margin-left: -30px;
  padding-right: 10px;
}
.btn-download {
  height: 40px;
  background: #3b4752;
  color: #ffffff;
  display: flex;
  padding: 0 20px;
  line-height: 0;
  font-size: 18px;
  border: transparent;
  border-radius: 20px;
  transition: 0.2s all;
  align-items: center;
}
.btn-download:hover {
  background: #346c91;
}
.btn-send {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #3b4752;
  color: #ffffff;
  margin-top: 10px;
}
.btn-send:hover {
  background: #346c91;
  color: #ffffff;
  text-decoration: none;
}
.btn-download span {
  padding-right: 10px;
  margin-right: 10px;
  border-right: 1px solid #ffffff;
}
.section-content {
  width: 100%;
  padding-bottom: 40px;
  position: relative;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 20px;
}
.docs-menu-content {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.docs-menu-content-title {
  margin-right: 10px;
}
.docs-menu-content-buttons {
  display: flex;
}
.docs-menu-content-buttons button {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  border: 2px solid #8c8536;
  background-color: #ffffff;
  color: #8c8536;
  transition: 0.2s all;
}
.docs-menu-content-buttons button:last-child {
  margin-left: 10px;
}
.docs-menu-content button:hover,
.docs-menu-content button.active {
  background-color: #8c8536;
  color: #ffffff;
}
.docs-list-content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.docs-container-search {
  display: flex;
  justify-content: center;
  align-items: center;
}
.docs-container-filter {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}
.docs-container-filter-2 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.error_content {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.error_title {
  font-size: 35px;
  line-height: 45px;
}
.error_error {
  font-size: 60px;
  line-height: 70px;
  color: #6a949b;
}
.error_description {
  font-size: 20px;
}
.text-note {
  width: 100%;
}
.btn-go-link {
  width: 140px;
  height: 40px;
  background: #3b4752;
  color: #ffffff;
  display: flex;
  padding: 0 20px;
  line-height: 0;
  font-size: 18px;
  border: transparent;
  border-radius: 20px;
  transition: 0.2s all;
  align-items: center;
  justify-content: center;
}
.btn-go-link:hover {
  background: #346c91;
  color: #ffffff;
  text-decoration: none;
}
@media screen and (max-width: 1000px) {
  .docs-container-search {
    flex-direction: column;
    margin-bottom: 20px;
  }
  .doc-item-content {
    width: calc(100% / 3);
  }
  .filter-select-content {
    height: 120px;
  }
}
@media screen and (max-width: 600px) {
  .title-section {
    font-size: 45px;
    line-height: 50px;
  }
  .doc-item-content {
    width: calc(100% / 2);
  }
}
.InitiativePage_content {
  padding-bottom: 40px;
}
.InitiativeButton_modal {
  margin-left: 5px;
  margin-right: 5px;
}
.InitiativeButton_modal .button-modal-text {
  background: #f59c00 !important;
}
.InitiativeButton_modal .button-modal-circle {
  background: url(../images/bg-circle-ligth-yellow.png) center no-repeat !important;
  background-size: 100% 100% !important;
}
.btn-survey {
  background: #ff8200;
  border: 2px solid #ff8200;
  color: #ffffff;
}
.btn-survey:hover {
  background: #de7402;
  border: 2px solid #de7402;
  color: #ffffff;
}
.description-news {
  margin-bottom: 30px;
}
