header {
  text-align: center;
  padding: 20px 10px;
}
header a {
  color: #4b4b4a;
}
header a:hover {
  color: #4b4b4a;
  text-decoration: none;
}
header p {
  font-family: "ConduitITCStd";
  font-size: 18px;
}
.header-logo {
  width: 300px;
  height: auto;
  display: block;
  margin: 30px auto 0;
}
.header-logo img {
  width: 100%;
}
.header-box-menu {
  width: 100%;
  height: 50px;
  background: #ffffff;
  position: relative;
  margin-bottom: 20px;
}
.header-line {
  width: 100%;
  height: 7px;
  background: #678293;
  position: absolute;
  top: 45px;
  left: 0;
}
.header-menu {
  width: 100%;
  height: 50px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-menu a {
  width: 200px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 13pt;
  line-height: 15pt;
  color: #4a4a49;
  position: relative;
  padding: 0 10px 5px;
  text-align: center;
}
.header-menu a:hover {
  color: #4a4a49;
  text-decoration: none;
}
.header-menu a div {
  width: 100%;
  height: 7px;
  position: absolute;
  top: 45px;
  left: 0;
  transition: 0.2s all;
}
.header-about div {
  background: lightgray !important;
}
.header-about-active div,
.header-tool-active div,
.header-document-active div,
.header-contact-active div,
.header-territory-active div,
.header-ecosystem-active div,
.header-news-active div,
.header-menu a:hover div {
  height: 15px !important;
}
.header-territory div {
  background: #5f3c7a !important;
}
.header-tool div {
  background: #d77561 !important;
}
.header-ecosystem div {
  background: #f59c00 !important;
}
.header-document div {
  background: #8c8536 !important;
}
.header-news div {
  background: #5e959c !important;
}
.header-contact div {
  background: lightgray !important;
}
.menu-responsive {
  display: none;
}
.close-menu {
  display: none;
}
.Header_session {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 5px 20px;
}
.Header_session button {
  padding: 0 10px;
  background-color: transparent;
  border: 0;
  transition: 0.2s all;
}
.Header_session button:hover {
  cursor: pointer;
  color: #678293;
}
@media screen and (max-width: 1024px) {
  .menu-responsive {
    display: block;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    background: transparent;
  }
  .menu-responsive span {
    margin-right: 5px;
  }
  .header-menu {
    width: 220px;
    height: 100%;
    overflow: auto;
    position: fixed;
    left: -230px;
    top: 0;
    right: initial;
    z-index: 5;
    flex-direction: column;
    background: #ffffff;
    justify-content: flex-start;
    transition: 0.5s all;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  .header-menu.visible {
    left: 0;
  }
  .header-menu a {
    width: 100%;
  }
  .close-menu {
    width: 40px;
    height: 40px;
    background: none;
    border: 3px solid #4a4a49;
    margin: 20px auto 5px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(45deg);
  }
  .header-menu a:hover div {
    height: 10px !important;
  }
  .header-about-active div,
  .header-tool-active div,
  .header-document-active div,
  .header-contact-active div,
  .header-territory-active div,
  .header-ecosystem-active div,
  .header-menu a:hover div {
    height: 10px !important;
  }
}
