.component-title {
  color: #5F3C7A;
  font-size: 24px;
  margin-bottom: 10px;
}
.component-title span {
  font-size: 16px;
}
.component-content {
  width: 100%;
  display: flex;
  margin-bottom: 30px;
}
.component-content iframe {
  width: 100%;
  height: 700px;
  border: 0;
}
.component-row {
  height: 35px;
  display: flex;
  align-items: center;
}
.component-name {
  width: calc(100% - 100px);
  padding: 0 10px;
  position: relative;
  cursor: pointer;
}
.component-number-title, .component-number {
  width: 50px;
}
.component-number {
  font-size: 18px;
}
.component-box {
  width: 400px;
  border: 1px solid #cccccc;
  border-radius: 3px;
  margin-right: 20px;
}
.component-tooltip {
  width: 225px;
  position: absolute;
  padding: 15px;
  border-radius: 5px;
  background: rgba(204, 204, 204, .9);
  font-size: 14px;
  line-height: 16px;
  visibility: hidden;
  opacity: 0;
  right: -40px;
  bottom: calc(100% + 5px);
  transition: .2s all;
}
.component-name:hover  .component-tooltip {
  visibility: visible;
  opacity: 1;
}
.component-tooltip-angle {
  width: 0;
  height: 0;
  border-top: 10px solid rgba(204, 204, 204, .9);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  position: absolute;
  left: 10px;
  bottom: -10px;
}
.component-chart {
  width: calc(100% - 420px);
}