.FilterItem_content {
  padding: 3px 5px 3px 10px;
  border-radius: 20px;
  color: #ffffff;
  font-size: 12pt;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 8px 8px 0;
}
.FilterItem_content button {
  width: 30px;
  height: 20px;
  padding: 0;
  padding-bottom: 3px;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  font-size: 24px !important;
  line-height: 0;
  color: #ffffff;
}
.FilterItem_content button:focus {
  outline: none;
}
