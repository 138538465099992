.TerritoryTooltip {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 2;
}
.TerritoryTooltip-button {
  width: 35px;
  height: 35px;
  border: 0;
  background-color: #e7e7e7;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 0;
  transition: 0.2s all;
}
.TerritoryTooltip-button.visible {
  border-radius: 0 0 50% 50%;
}
.TerritoryTooltip-button:hover {
  background-color: #5f3c7a;
  color: #ffffff;
}
.TerritoryTooltip-container {
  width: 280px;
  margin-bottom: 35px;
  background-color: #e7e7e7;
  padding: 10px 15px;
  border-radius: 20px 20px 0 20px;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s all;
}
.TerritoryTooltip-container.visible {
  opacity: 1;
  visibility: visible;
}
.TerritoryTooltip-container a {
  color: #000000;
}
.TerritoryTooltip-container a:hover {
  color: #5f3c7a;
  text-decoration: none;
}
