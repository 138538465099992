.InitiativeButtonExcel_container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.InitiativeButtonExcel_table {
  opacity: 0;
  visibility: hidden;
  display: none;
}
.InitiativeButtonExcel_button {
  width: 180px;
  height: 50px;
  border: 0;
  background-color: #f59c00;
  color: #ffffff;
  border-radius: 10px;
  margin-bottom: 20px;
  transition: 0.1s all;
}
.InitiativeButtonExcel_button:hover {
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.55);
}
.InitiativeButtonExcel_button:focus {
  outline: none;
}
