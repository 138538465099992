.territoryInfoPage_menu-container {
  width: 100%;
  padding-top: 5px;
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid #5f3c7a;
  margin-top: 20px;
  overflow-x: auto;
  overflow-y: hidden;
}
.territoryInfoPage_menu-container::-webkit-scrollbar {
  display: none;
}
.territoryInfoPage_menu {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-wrap: nowrap;
}
.territoryInfoPage_menu a,
.territoryInfoPage_menu button {
  width: 250px;
  height: 45px;
  border-radius: 40px 40px 0 0;
  background-color: #5f3c7a;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  transition: 0.2s all;
  font-size: 25px;
  text-align: center;
}
.territoryInfoPage_menu a.active {
  background-color: #a17dbd;
}
.territoryInfoPage_menu a:hover {
  color: #ffffff;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.65);
  text-decoration: none;
}
.territoryInfoPage_content {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}
.territoryInfoPage_submenu {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
}
.territoryInfoPage_submenu a {
  width: 100%;
  height: 50px;
  border: 0;
  transition: 0.2s all;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14pt;
  line-height: 15pt;
  padding: 0 5px;
}
.territoryInfoPage_submenu a.active {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.5);
  position: relative;
  z-index: 1;
}
.territoryInfoPage_submenu a:hover {
  opacity: 0.75;
  text-decoration: none;
}
.territoryInfoPage_submenu-mobile {
  display: none !important;
}
.territoryInfoPage_container {
  width: 100%;
}
.territoryInfoPage_item {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #cccccc;
}
.territoryInfoPage_download {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}
.territoryInfoPage_download h3 {
  width: calc(100% - 50px);
}
.territoryInfoPage_download a {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.territoryInfoPage_graph {
  width: 100%;
  height: 600px;
}
.territoryInfoPage_doc {
  width: 100%;
  height: 400px;
}
.territoryInfoPage_note {
  font-size: 20px;
}
.territoryInfoPage_container h3 {
  text-align: center;
}
.territoryInfoPage_item h3 {
  text-align: left;
}
.territoryInfoPage_btn-request {
  width: 200px;
  height: 40px;
  border: 0;
  display: block;
  margin: 0 auto;
  font-size: 20px;
  transition: 0.2s all;
}
.territoryInfoPage_btn-request:hover {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.75);
}
.territoryInfoPage_container h1 {
  font-family: "Oswald-Medium";
  margin-bottom: 20px;
}
.territoryInfoPage_box-button {
  width: 100%;
  margin: 0 auto 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.territoryInfoPage_button-video {
  padding: 10px 20px;
  font-size: 18pt;
  background-color: #ececec;
  border-radius: 10px;
  transition: 0.2s all;
}
.territoryInfoPage_button-video:hover {
  background-color: #ffffff;
}
.territoryInfoPage_button-video:active {
  transform: translateY(4px);
  box-shadow: 0 2px 0 !important;
}
.territoryInfoPage_video-box {
  position: relative;
  width: 100%;
  max-width: 600px;
  height: auto;
  margin: 0 auto;
}
.territoryInfoPage_video {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  overflow: hidden;
  background-color: #000000;
}
.territoryInfoPage_video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.territoryInfoPage_video-box button {
  width: 40px;
  height: 40px;
  position: absolute;
  top: -15px;
  right: -15px;
  z-index: 1;
  border-radius: 50%;
  font-size: 16pt;
  padding: 0;
  border: 2px solid #ffffff;
  transition: 0.2s all;
}
.territoryInfoPage_video-box button:hover {
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.75);
}
@media screen and (max-width: 768px) {
  .territoryInfoPage_menu-container {
    justify-content: flex-start;
  }
  .territoryInfoPage_submenu-mobile {
    display: block !important;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .territoryInfoPage_submenu {
    display: none;
  }
  .territoryInfoPage_content {
    flex-direction: column;
  }
  .territoryInfoPage_container {
    width: 100%;
  }
  .territoryInfoPage_menu {
    padding-left: 0;
  }
  .territoryInfoPage_menu a,
  .territoryInfoPage_menu button {
    max-width: 250px;
    margin-right: 0;
  }
}
@media screen and (max-width: 600px) {
  .territoryInfoPage_menu a,
  .territoryInfoPage_menu button {
    border-radius: 30px 30px 0 0;
    width: 150px;
    font-size: 16px;
    line-height: 18px;
  }
}
