.video-home {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}
.video-home p {
  margin: 0;
}
.video-home img {
  width: 100%;
}
.index-description {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}
.title-home {
  margin-top: 20px;
}
.index-circle-description {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #80bad7;
  color: #33414f;
  margin-right: 20px;
  font-size: 65px;
  line-height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.index-text-description {
  display: flex;
  align-items: center;
  width: calc(100% - 120px);
  line-height: 22px;
}
.index-title-description {
  font-size: 22px;
  width: 50px;
}
.index-boxes-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.index-box-container {
  width: 25%;
  display: flex;
  flex-direction: column;
  color: #4a4a49;
}
.index-box-line {
  width: 100%;
  height: 20px;
}
.index-box-content {
  width: 100%;
  height: calc(100% - 20px);
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.index-box-data {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.index-box-data p {
  margin-bottom: 0;
}
.index-box-content span {
  font-size: 80px;
}
.index-box-territory .index-box-content {
  background: rgba(95, 60, 122, 0.1);
}
.index-box-tools .index-box-content {
  background: rgba(94, 149, 156, 0.1);
}
.index-box-ecosystem .index-box-content {
  background: rgba(245, 156, 0, 0.1);
}
.index-box-documents .index-box-content {
  background: rgba(140, 133, 54, 0.1);
}
.index-box-territory span {
  color: #fcbd00;
}
.index-box-tools span {
  color: #5f3c7a;
}
.index-box-ecosystem span {
  color: #8e486a;
}
.index-box-documents span {
  color: #346c91;
}
.index-box-title {
  width: 100%;
  font-size: 25px;
  line-height: 25px;
  margin-bottom: 10px;
}
.index-box-text {
  padding: 15px 0;
}
.search-container {
  width: 100%;
  margin-bottom: 30px;
}
.search-container h4 {
  color: #4a4a49;
  margin: 20px 0 30px;
  border-bottom: 1px solid #4a4a49;
}
.title-search {
  margin: 10px 0 20px;
}
.search-loading {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.search-loading img {
  width: 80px;
}
.search-item {
  width: 100%;
  margin-bottom: 25px;
  padding-bottom: 10px;
  border-bottom: 1px solid #678293;
}
.search-item-menu {
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
}
.search-item-menu a {
  border: 1px solid #346c91;
  color: #346c91;
  padding: 5px 10px 3px;
  border-radius: 5px;
  transition: 0.2s all;
}
.search-item-menu a:hover {
  color: #ffffff;
  background: #346c91;
  text-decoration: none;
}
.search-coverpage {
  width: 120px;
  margin: 0 15px 0 0;
}
.searc-box {
  display: flex;
}
@media screen and (max-width: 811px) {
  .index-boxes-container {
    flex-direction: column;
  }
  .index-box-container {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .video-box {
    width: 100%;
  }
  .video-home iframe {
    width: 100%;
    height: 300px;
  }
}
