.slider_slide {
  width: 100%;
  height: 100%;
  position: relative;
}
.slider_overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
}
.slider_content {
  position: absolute;
  left: 20px;
  bottom: 30px;
}
.slider_overlay a {
  width: 80px;
  height: 35px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  border: 0;
  transition: 0.2s all;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
}
.slider_overlay a:hover {
  text-decoration: none;
  color: #000000;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.75);
}
.slider_slide img {
  width: 100%;
  display: block;
  height: auto;
  margin: 0;
}
.slider_section {
  margin-bottom: -10px;
}
.slider_section .slick-dots {
  bottom: 10px;
}
.slider_section .slick-dots li {
  width: 10px;
  height: 10px;
}
.slider_section .slick-dots li button:before {
  width: 10px;
  height: 10px;
  background: #b1b1b1;
  content: "";
  opacity: 1;
  transition: 0.2s all;
  border-radius: 50%;
}
.slider_section .slick-dots li:hover button:before {
  background: #b1b1b1;
  opacity: 0.7;
}
.slider_section .slick-dots li.slick-active button:before {
  background: #ffffff;
}
.slider_title {
  font-size: 60px;
  text-transform: uppercase;
  color: #ffffff;
  line-height: 60px;
}
@media screen and (max-width: 650px) {
  .slider_title {
    font-size: 25px;
    line-height: 35px;
  }
}
