.doc-detail-content {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #cccccc;
}
.doc-detail-content img {
  width: 100%;
}
.doc-detail-content h1 {
  color: #346c91;
}
.doc-detail-description {
  text-align: justify;
  margin-bottom: 20px;
}
.doc-detail-complement {
  margin-bottom: 20px;
}
.doc-detail-row {
  display: flex;
}
.doc-detail-label {
  margin-right: 7px;
  font-weight: bold;
}
.doc-detail-authors span:after {
  content: ",";
  margin-right: 5px;
}
.doc-detail-authors span:last-child:after {
  content: "";
  margin-right: 0;
}
