.pagination-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination-content button {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: transparent;
  line-height: 0;
  display: flex;
  justify-content: center;
  padding: 0;
  align-items: center;
  font-size: 16px;
  margin: 0 1px;
  border: transparent;
}
.pagination-content button.active {
  background: #80BAD7 !important;
  color: #ffffff;
}
.pagination-arrow {
  background: #346C91 !important;
  color: #ffffff;
}
@media screen and (max-width: 768px) {
  .pagination-content {
    margin-bottom: 20px;
  }
}