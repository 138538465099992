.Detail_modal {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
}
.Detail_container {
  width: calc(100% - 40px);
  max-width: 800px;
  height: calc(100% - 40px);
  background-color: #ffffff;
  padding: 20px;
  border-radius: 15px;
  position: relative;
}
.Detail_content {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.Detail_close {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 35px;
  color: #f59c00;
  transition: 0.2s all;
  z-index: 1;
}
.Detail_close:hover {
  color: #d88900;
  text-decoration: none;
}
.Detail_loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Detail_loading img {
  width: 100px;
}
.Detail_video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  margin: 15px 0;
}
.Detail_video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.Detail_slide {
  padding: 0 10px;
}
.Detail_slide img {
  height: 250px;
  display: block;
  margin: 0 auto;
  object-fit: contain;
}
.Detail_subtitle {
  width: 100%;
  border-bottom: 2px solid #f59c00;
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 0 10px;
  font-weight: bold;
  font-size: 20px;
}
.Detail_files {
  display: flex;
  flex-direction: column;
}
.Detail_files a {
  width: 100%;
  padding: 10px;
  background-color: #efeeee;
  color: #000000;
  border-radius: 5px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  transition: 0.2s all;
}
.Detail_files a i {
  margin-right: 5px;
  font-size: 20px;
}
.Detail_files a:hover {
  text-decoration: none;
  background-color: #fbdda9;
  color: #000000;
}
.Detail_content h1 {
  font-weight: bold;
  font-size: 30px;
}
.Detail_link {
  border: 1px solid #949494;
  color: #f59c00;
  transition: 0.2s all;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 5px 10px;
}
.Detail_link span {
  margin-right: 10px;
}
.Detail_link:hover {
  background-color: #f59c00;
  color: #ffffff;
  text-decoration: none;
}
.Detail_icons {
  margin-top: 10px;
}
