.territory-cities {
  width: 310px;
  margin-right: 20px;
}
.territory-container {
  width: 100%;
  border: 1px solid #CCCCCC;
  border-radius: 3px;
  padding: 10px 0;
  font-size: 14px;
}
.territory-row {
  display: flex;
}
.territory-col {
  width: 50%;
  padding: 0 5px;
  display: flex;
  flex-direction: column;
}
.territory-col:nth-child(2) {
  border-left: 1px solid #6D6D6D;
}
.territory-col a {
  width: 100%;
  height: 25px;
  display: flex;
  align-items: center;
  padding: 0 3px;
  color: #4A4A49;
}
.territory-icon {
  width: 18px;
}
.territory-icon span {
  display: none;
}
.territory-active {
  background: #ffffff;
  border: 1px solid #CCCCCC;
}
.territory-active .territory-icon span {
  display: block;
}
.territory-col a.purple:hover {
  color: #5F3C7A;
  text-decoration: none;
}
.territory-active.purple {
  color: #5F3C7A !important;
}
.territory-col a.yellow:hover {
  color: #F59C00;
  text-decoration: none;
}
.territory-active.yellow {
  color: #F59C00 !important;
}
.national-territory {
  width: 100%;
  display: flex;
  font-size: 18px;
  border-bottom: 1px solid #CCCCCC;
  margin-bottom: 5px;
}
.national-territory.purple {
  color: #5F3C7A !important;
}
.national-territory.purple .territory-name {
  color: #5F3C7A !important;
}
.national-territory.yellow {
  color: #F59C00;
}
@media screen and (max-width: 1000px) {
  .territory-cities {
    width: calc(50% - 10px);
  }
}
@media screen and (max-width: 765px) {
  .territory-cities {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}