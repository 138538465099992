.button span {
  margin-right: 4px;
}
.button {
  width: 70px;
  height: 32px;
  background-color: #fcfcfc;
  border: 1px solid #949494;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: 0.2s all;
  border-radius: 3px;
  font-size: 13px !important;
}
.button-purple {
  color: #5f3c7a !important;
  position: relative;
}
.button-purple:hover {
  background-color: #5f3c7a;
  color: #ffffff !important;
}
.button-yellow {
  color: #f59c00 !important;
  position: relative;
}
.button-yellow:hover {
  background-color: #f59c00;
  color: #ffffff !important;
}
