.tooltip_container {
  min-width: 100px;
  max-width: 150px;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  border: 10px;
  top: 40px;
  background-color: #000000;
  color: #ffffff;
  visibility: hidden;
  opacity: 0;
  transition: .3s all;
}
.button:hover > .tooltip_container {
  opacity: 1;
  visibility: visible;
}
.tooltip_arrow {
  width: 8px;
  display: block;
  margin: auto;
  left: 0;
  right: 0;
  border-left: 6px solid transparent;
  border-bottom: 8px solid black;
  border-right: 6px solid transparent;
  position: absolute;
  top: -8px;
}