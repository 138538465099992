.footer-subscriber {
  width: 100%;
  height: 80px;
  background: #80bad7;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.footer-subscriber-box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-input-subscriber {
  width: 80%;
  max-width: 350px;
  height: 40px;
  background: #ffffff;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-input-subscriber input {
  width: calc(100% - 50px);
  height: 100%;
  margin: 0 5px;
  border: 0;
  padding: 0 5px;
  color: #33414f;
  font-size: 20px;
}
.footer-input-subscriber input::placeholder {
  color: #33414f;
  opacity: 1;
}
.footer-input-subscriber input:-ms-input-placeholder {
  color: #33414f;
}
.footer-input-subscriber input::-ms-input-placeholder {
  color: #33414f;
}
.footer-input-subscriber span {
  color: #678293;
  font-size: 30px;
}
.footer-btn-subscriber {
  width: 80px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background: #3b4752;
  border: 0;
  border-radius: 5px;
  transition: 0.2s all;
}
.footer-btn-subscriber:hover {
  background: #323a3f;
}
footer {
  width: 100%;
  color: #ffffff;
  background: #010101;
}
.footer {
  padding: 30px 0 !important;
  background: #010101 !important;
}
.content-footer {
  border-bottom: 1px solid #ffffff;
  padding-bottom: 30px;
  margin-bottom: 20px;
}
.footer-title {
  font-size: 20px;
  display: flex;
  align-items: center;
}
.footer-title div {
  width: 80px;
  height: 2px;
  background: #ffffff;
  margin-left: 10px;
}
.footer-copy {
  width: 100%;
  padding: 15px 10px;
  text-align: center;
  background: #1b1b1b;
  font-size: 12px;
}
.footer-copy a {
  color: #ffffff;
}
.footer-copy a:hover {
  color: #ffffff;
}
.footer-social {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
}
.footer-social a {
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  transition: 0.2s all;
  border-right: 1px solid #ffffff;
  font-size: 20px;
}
.footer-social a:nth-child(1) {
  margin-left: 0;
}
.footer-social a:hover {
  color: #ffffff;
  text-decoration: none;
  opacity: 0.75;
}
.footer-social-title {
  color: #ffffff;
  text-align: center;
}
.footer_images {
  width: 100%;
  max-width: 150px;
  display: block;
  margin: 0 auto;
}
.footer_contact p {
  margin: 0;
  font-size: 14pt;
}
.footer_logo {
  width: 100%;
  max-width: 250px;
}
.footer-logotypes {
  width: 100%;
  display: block;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}
.logotype {
  width: 100%;
}
.footer-logotypes img {
  width: 100%;
  max-width: 150px;
  margin: 0 auto 20px;
  display: block;
}
@media screen and (max-width: 770px) {
  .footer-col {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 330px) {
  .footer-input-subscriber {
    width: 180px;
  }
}
