.tutorial-container {
  width: 100%;
  position: relative;
  padding-top: 40px;
}
.tutorial-close {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: #ffffff;
  color: #5F3C7A;
  font-size: 30px;
  line-height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: .2s all;
  position: absolute;
  top: 20px;
  right: 0;
}
.tutorial-close:hover {
  opacity: .75;
}
.modal-tutorial-content {
  width: 100%;
  height: 100%;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  background: rgba(95, 60, 122, .88);
  opacity: 0;
  visibility: hidden;
  transition: .3s all;
}
.modal-tutorial-content.active {
  visibility: visible;
  opacity: 1;
}
.tutorial-header {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
}
.tutorial-number {
  font-size: 50px;
  line-height: 50px;
  color: #FCD116;
  margin-bottom: 30px;
}
.tutorial-step4 {
  margin-bottom: 0px;
}
.tutorial-col-01 {
  width: 30%;
  border-right: 1px solid #ffffff;
  padding: 20px 0;
}
.tutorial-col-02 {
  width: 40%;
  padding: 20px 20px 20px;
}
.tutorial-col-02:nth-child(2) {
  border-right: 1px solid #ffffff;
}
.tutorial-col-01, .tutorial-col-02 {
  border-bottom: 1px solid #ffffff;
}
.intro-table {
  height: 400px;
  display: block;
  margin-bottom: 20px;
}
.tutorial-col-01 img, .intro-map {
  display: block;
  margin: 0 auto 20px;
}
.tutorial-step02 {
  display: flex;
  position: relative;
}
.intro-arrow {
  width: 80px;
  height: auto;
  position: absolute;
  top: -5px;
  left: 160px;
}
.intro-txt {
  color: #ffffff;
  line-height: 18px;
  margin-bottom: 20px;
}
.intro-txt-step02 {
  padding: 30px 0 20px 20px;
}
.tutorial-col-01 .intro-txt {
  padding-right: 20px;
}
.tutorial-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ffffff;
  padding-bottom: 30px;
}
.tutorial-footer img {
  width: calc(100% - 400px);
  height: auto;
  margin-right: 20px;
}
.tutorial-footer .intro-txt {
  width: 300px;
}
.intro-txt-02 {
  width: 270px;
}
.intro-txt-04 {
  width: 270px;
  margin: 0 auto;
}
.tutorial-end {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: 640px;
  margin: 0 auto 30px;
}
.tutorial-end .intro-txt {
  max-width: 250px;
  height: 100px;
  margin-left: 20px;
}
.tutorial-end img {
  width: 100%;
  max-width: 300px;
}
.tutorial-step5 {
  position: absolute;
  top: 0;
  left: 0;
}
.intro-box {
  width: 100%;
  max-width: 300px;
  display: block;
  margin: 0 auto 20px;
}
@media screen and (max-width: 1000px) {
  .tutorial-footer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .tutorial-footer img {
    width: 100%;
    margin-bottom: 10px;
  }
  .tutorial-footer .intro-txt {
    width: 100% !important;
  }
  .tutorial-header {
    flex-wrap: wrap;
  }
  .tutorial-col-01, .tutorial-col-02 {
    width: 100%;
    border-right: 0;
  }
  .tutorial-col-02:nth-child(2) {
    border-right: 0;
  }
  .intro-map {
    width: 200px;
    height: auto;
  }
}