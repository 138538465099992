.counter_container {
  width: 100%;
  max-width: 200px;
  padding: 15px 0;
  border-radius: 5px;
  border: 1px solid #CCCCCC;
}
.counter_box {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 40px;
}