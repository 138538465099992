.bar-horizontal-content {
  display: block;
  padding: 0 10px 8px;
}
.bar-horizontal-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bar-horizontal-title {
  margin-right: 10px;
}
.bar-horizontal-bar-content {
  width: calc(100% - 50px);
  height: 23px;
  position: relative;
}
.bar-horizontal-number {
  width: 50px;
  text-align: right;
}
.bar-horizontal-circle1 {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  padding: 2px;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
}
.bar-horizontal-circle2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid #ffffff;
}
.bar-horizontal-progress {
  width: calc(100% - 10px);
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}
.bar-horizontal-line {
  width: 100%;
  height: 2px;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
}
.bar-horizontal-progressbar {
  height: 100%;
}
.bar-horizontal-bar-content.purple1 .bar-horizontal-circle1 {
  background: #614784;
}
.bar-horizontal-bar-content.purple1 .bar-horizontal-line {
  background: #614784;
}
.bar-horizontal-bar-content.purple1 .bar-horizontal-progressbar {
  background: #614784;
}
.bar-horizontal-bar-content.purple2 .bar-horizontal-circle1 {
  background: #6e4075;
}
.bar-horizontal-bar-content.purple2 .bar-horizontal-line {
  background: #6e4075;
}
.bar-horizontal-bar-content.purple2 .bar-horizontal-progressbar {
  background: #6e4075;
}
.bar-horizontal-bar-content.purple3 .bar-horizontal-circle1 {
  background: #7d4470;
}
.bar-horizontal-bar-content.purple3 .bar-horizontal-line {
  background: #7d4470;
}
.bar-horizontal-bar-content.purple3 .bar-horizontal-progressbar {
  background: #7d4470;
}
.bar-horizontal-bar-content.purple4 .bar-horizontal-circle1 {
  background: #8e486a;
}
.bar-horizontal-bar-content.purple4 .bar-horizontal-line {
  background: #8e486a;
}
.bar-horizontal-bar-content.purple4 .bar-horizontal-progressbar {
  background: #8e486a;
}
.bar-horizontal-bar-content.purple5 .bar-horizontal-circle1 {
  background: #9b4c65;
}
.bar-horizontal-bar-content.purple5 .bar-horizontal-line {
  background: #9b4c65;
}
.bar-horizontal-bar-content.purple5 .bar-horizontal-progressbar {
  background: #9b4c65;
}
.bar-horizontal-bar-content.purple6 .bar-horizontal-circle1 {
  background: #a35d71;
}
.bar-horizontal-bar-content.purple6 .bar-horizontal-line {
  background: #a35d71;
}
.bar-horizontal-bar-content.purple6 .bar-horizontal-progressbar {
  background: #a35d71;
}
.bar-horizontal-bar-content.purple7 .bar-horizontal-circle1 {
  background: #ad6e7f;
}
.bar-horizontal-bar-content.purple7 .bar-horizontal-line {
  background: #ad6e7f;
}
.bar-horizontal-bar-content.purple7 .bar-horizontal-progressbar {
  background: #ad6e7f;
}
.bar-horizontal-bar-content.purple8 .bar-horizontal-circle1 {
  background: #b6808d;
}
.bar-horizontal-bar-content.purple8 .bar-horizontal-line {
  background: #b6808d;
}
.bar-horizontal-bar-content.purple8 .bar-horizontal-progressbar {
  background: #b6808d;
}
.bar-horizontal-bar-content.purple9 .bar-horizontal-circle1 {
  background: #b68b95;
}
.bar-horizontal-bar-content.purple9 .bar-horizontal-line {
  background: #b68b95;
}
.bar-horizontal-bar-content.purple9 .bar-horizontal-progressbar {
  background: #b68b95;
}
