.Blog_loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.Blog_loading img {
  width: 75px;
}
.Blog_menu {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
}
.Blog_menu a {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px 15px;
  background: #ececec;
  font-size: 15pt;
  margin: 0 5px;
  border-radius: 8px;
  color: #000000;
  transition: 0.2s all;
}
.Blog_menu a:hover {
  background-color: #e2e1e1;
  text-decoration: none;
  color: #000000;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
.Blog_menu a.active {
  background-color: #5e959c;
  color: #ffffff;
}
.Blog_articles,
.Blog_categories {
  padding-bottom: 20px;
}
.Blog_categories-container {
  background-color: #ececec;
  height: 100%;
  padding: 10px;
  border-radius: 8px;
}
.Blog_categories-container h2 {
  font-size: 20pt;
  margin-bottom: 0;
}
.Blog_categories-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Blog_categories-header button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #5e959c;
  color: #ffffff;
  border: 0;
  transition: 0.2s all;
  display: none;
}
.Blog_categories-header button.show {
  transform: rotate(180deg);
}
.Blog_categories-content {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}
.Blog_categories-content a {
  cursor: pointer;
  width: 100%;
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 14pt;
  line-height: 15pt;
  color: #000000;
  transition: 0.2s all;
}
.Blog_categories-content a:hover {
  text-decoration: none;
  color: #5e959c;
}
.Blog_categories-content a.active {
  background-color: #fafafa;
}
.Blog_pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.Blog_pagination a {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  text-align: center;
  background-color: #ececec;
  color: #000000;
  margin: 2px;
  border-radius: 50%;
}
.Blog_pagination a:hover {
  background-color: #e2e1e1;
  text-decoration: none;
  color: #000000;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
.Blog_pagination a.active {
  background-color: #5e959c;
  color: #ffffff;
}
.Blog_article-resume {
  width: 100%;
  height: 100%;
}
.Blog_article-image {
  width: 100%;
  height: 175px;
  overflow: hidden;
  border-radius: 10px 10px 0 0;
  background-color: #5e959c;
}
.Blog_article-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.Blog_article-resume h2 {
  font-size: 20pt;
}
.Blog_article-content {
  width: 100%;
  height: calc(100% - 175px);
  padding: 15px;
  border: 1px solid #5e959c;
  border-top: 0;
  border-radius: 0 0 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}
.Blog_article-content a {
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 6px 0 #5e959c;
  border: 1px solid #5e959c;
  text-decoration: none;
  font-size: 14pt;
  transition: 0.2s all;
  color: #000000;
}
.Blog_article-content a:hover {
  background-color: #f0f0f0;
  color: #000000;
  text-decoration: none;
}
.Blog_article-content a:active {
  transform: translateY(4px);
  box-shadow: 0 2px 0 #5e959c;
}
.Blog_article-date {
  font-size: 12pt;
  margin-bottom: 5px;
  color: #a7a7a7;
  font-family: "ConduitITC-bold";
}
.Blog_article-detail-image {
  width: 100%;
  height: 250px;
  overflow: hidden;
  background-color: #5e959c;
  border-radius: 10px;
}
.Blog_article-detail-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.Blog_article-detail-content {
  width: calc(100% - 30px);
  margin: -30px auto 0;
  background-color: #ffffff;
  z-index: 1;
  position: relative;
  padding: 10px;
  border-radius: 10px 10px 0 0;
  border: 0;
}
.Blog_article-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.Blog_article-header h1 {
  margin: 0;
}
.Blog_article-categories {
  font-size: 13pt;
}
.Blog_article-categories span {
  color: #a7a7a7;
  margin-right: 5px;
}
.Blog_article-categories a {
  cursor: pointer;
  color: #000000;
  text-decoration: none;
  transition: 0.2s all;
}
.Blog_article-categories a:hover {
  color: #5e959c;
  text-decoration: none;
}
@media screen and (max-width: 768px) {
  .Blog_menu {
    flex-wrap: wrap;
  }
  .Blog_menu a {
    cursor: pointer;
    width: 150px;
    margin: 5px;
  }
  .Blog_categories-header button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Blog_categories-content {
    display: none;
  }
  .Blog_categories-content.show {
    display: flex;
  }
  .Blog_categories {
    order: 1;
  }
  .Blog_articles {
    order: 2;
  }
  .Blog_article-header {
    flex-direction: column;
    align-items: flex-start;
  }
  .Blog_article .col-md-6,
  .Blog_article-resume {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 350px) {
  .Blog_menu a {
    cursor: pointer;
    width: 100%;
    margin: 0 0 5px;
  }
}
