.tools-btn-tutorial {
  position: absolute !important;
  right: 0;
  bottom: 50px;
}
@media screen and (max-width: 768px) {
  .tools-btn-tutorial {
    position: relative !important;
    display: block !important;
    margin: 20px auto 0 !important;
    bottom: 0 !important;
  }
}