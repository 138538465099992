.map-content {
  width: 100%;
  position: relative;
}
.map-content img {
  width: 100%;
}
.map-button {
  width: 240px;
  height: 40px;
  margin: 0 auto 20px;
  position: relative;
  cursor: pointer;
}
.map-button-circle {
  width: 46px;
  height: 40px;
  background: url(../../images/bg-circle.png) center no-repeat;
  background-size: 100% 100%;
  color: #5F3C7A;
  font-size: 30px;
  padding-left: 5px;
  position: absolute;
  left: 0;
  z-index: 1;
}
.map-button-text {
  width: 230px;
  height: 30px;
  border-radius: 15px;
  font-size: 20px;
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  background: #5F3C7A;
  color: #ffffff;
  display: flex;
  align-items: center;
  padding-left: 40px;
}