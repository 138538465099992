.ecosystem-docs-content {
  width: 100%;
  margin-bottom: -20px;
}
.ecosystem-docs-row {
  width: 100%;
  margin-bottom: 20px;
}
.ecosystem-docs-title-section {
  width: 100%;
}
.ecosystem-docs-title-section h5 {
  color: #F59C00;
  text-transform: uppercase;
  border-bottom: 1px solid #F59C00;
  margin-bottom: 10px;
  padding-bottom: 3px;
}
.ecosystem-docs-col {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.ecosystem-docs-item {
  width: calc(100% / 2 - 20px);
  display: flex;
  border-bottom: 1px solid #CCCCCC;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.ecosystem-docs-info {
  width: calc(100% - 170px);
  padding-right: 20px;
}
.ecosystem-docs-title {
  font-size: 19px;
  text-transform: uppercase;
  color: #4A4A49;
}
.ecosystem-docs-links {
  word-break: break-all;
  color: #949494;
}
.ecosystem-docs-menu {
  width: 170px;
  display: flex;
  justify-content: flex-end;
}
.ecosystem-docs-button {
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  border: 1px solid #949494;
  background: #ffffff;
  color: #F59C00;
  font-size: 14px;
  transition: .2s all;
  border-radius: 5px;
  margin-left: 5px;
}
.ecosystem-docs-button span {
  margin-right: 5px;
  font-size: 16px;
}
.ecosystem-docs-button:hover {
  color: #ffffff;
  background: #F59C00;
  text-decoration: none;
}
@media screen and (max-width: 1000px) {
  .ecosystem-docs-item {
    width: 100%;
  }
}
@media screen and (max-width: 550px) {
  .ecosystem-docs-info {
    width: calc(100% - 90px);
  }
  .ecosystem-docs-menu {
    width: 90px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .ecosystem-docs-button {
    width: 100% !important;
    font-size: 12px;
    margin-bottom: 5px;
  }
}