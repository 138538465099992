.tools-menu-content {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
}
.tools-menu-item {
  width: calc(100% / 5);
}
.tools-menu-number {
  text-align: center;
}
.tools-menu-item button {
  width: 100%;
  height: 90px;
  font-size: 18px;
  padding: 0;
}
.tools-menu-button-1 {
  background: #D77561;
  border: 3px solid #D77561;
}
.tools-menu-button-2 {
  background: #D68663;
  border: 3px solid #D68663;
}
.tools-menu-button-3 {
  background: #D39663;
  border: 3px solid #D39663;
}
.tools-menu-button-4 {
  background: #D1A565;
  border: 3px solid #D1A565;
}
.tools-menu-button-5 {
  background: #CE9F42;
  border: 3px solid #CE9F42;
}
.tools-menu-item button.active {
  border: 3px solid #346C91;
}