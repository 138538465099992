.boxStage-title {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 4px;
  border-bottom: 1px solid #6D6D6D;
}
.boxState-content {
  display: flex;
}
.boxStage-box {
  width: 50%;
  margin-bottom: 10px;
  align-items: center;
  text-align: center;
}
.boxStage-box:nth-child(2) {
  border-left: 1px solid #6d6d6d;
}
.boxStatge-number {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 35px;
  line-height: 0;
  color: #5F3C7A;
}
.boxStage-subtitle {
  color: rgba(74, 74, 73, .45);
  position: relative;
  cursor: context-menu;
}