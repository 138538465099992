.Forms_modal {
  width: 100%;
  height: 100%;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.75);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all;
  overflow: auto;
}
.Forms_modal label {
  margin-bottom: 0px;
}
.Forms_modal h1 {
  text-align: center;
  font-size: 30px;
}
.Forms_modal.visible {
  opacity: 1;
  visibility: visible;
}
.Forms_container {
  width: 90%;
  max-width: 600px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 20px;
  margin: 20px auto;
  position: relative;
}
.Forms_close {
  width: 40px;
  height: 40px;
  position: absolute;
  background-color: transparent;
  border: 0;
  top: 0;
  right: 0;
  font-size: 25px;
}
.Forms_button {
  width: 100%;
  max-width: 300px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0 auto;
  border: 0;
  background-color: #000000;
  color: #ffffff;
  border-radius: 5px;
  border: 1px solid #000000;
  transition: 0.2s all;
}
.Forms_button:hover {
  background-color: #d8d8d8;
  color: #000000;
}
.Form_action {
  margin-top: 8px;
  cursor: pointer;
}
.Forms_loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.7);
  color: #ffffff;
  font-size: 25px;
  text-align: center;
}
.Forms_loading img {
  width: 100px;
}
.Forms_terms {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 5px;
}
.Forms_terms input[type="checkbox"] {
  margin-top: 5px;
  margin-right: 8px;
}
