.news_content {
  margin-bottom: 30px;
}
.news_loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.news_loading img {
  width: 80px;
}
.news_section {
  border-bottom: 3px solid #CCCCCC;
  padding: 10px 0;
}
.news_title {
  font-family: 'ConduitITC';
  color: #6A949B;
  font-size: 25px;
  margin-bottom: 10px;
  font-weight: bold;
}
.news_subtitle {
  font-family: 'ConduitITC';
  color: #6A949B;
  font-size: 22px;
}
.news_section .doc-item-content {
  border-top: 0;
}
.news_section .doc-item-coverpage img {
  height: 100%;
}
.news_section .doc-item-box {
  width: 90%;
}
.news_section .doc-item-coverpage {
  width: 100%;
}
.news_item {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.new_row {
  display: flex;
  flex-wrap: wrap;
}
.news_section .slick-prev:before{
  color:#6A949B;
}
.news_section .slick-next:before{
  color:#6A949B;
}
@media screen and (max-width: 590px) {
  .slick-prev {
    left: -15px;
  }
  .slick-next {
    right: -15px;
  }
}
.news_detail-header {
  width: 100%;
  height: 300px;
  position: relative;
  margin-bottom: 20px;
}
.news_detail-header img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.news_detail-content {
  margin-bottom: 30px;
}
@media screen and (max-width: 540px) {
  .slick-prev {
    left: -5px;
  }
  .slick-next {
    right: -5px;
  }
}
.news_row-category {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.news_category {
  padding: 5px 15px;
  border: 1px solid #6A949B;
  margin: 0 5px 20px;
  border-radius: 10px;
  font-weight: bold;
  color: #6A949B;
  cursor: pointer;
  transition: .2s all;
  font-size: 18px;
}
.news_category.selected {
  background-color: #6A949B;
  color: #ffffff;
}
.news_category:hover {
  background-color: #6A949B;
  color: #ffffff;
}
.news_subcategory {
  padding-top: 10px;
  border-top: 1px solid #CCCCCC;
}
.news_row-subcategory {
  display: flex;
  flex-wrap: wrap;
}
.news_item-subcategory {
  padding: 5px 15px;
  border-radius: 10px;
  border: 1px solid #9d9d9d;
  margin-right: 5px;
  margin-bottom: 5px;
  color: #9d9d9d;
  cursor: pointer;
  transition: .2s all;
}
.news_item-subcategory.selected {
  background-color: #9d9d9d;
  color: #ffffff;
}
.news_item-subcategory:hover {
  background-color: #9d9d9d;
  color: #ffffff;
}