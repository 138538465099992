.modal-download {
  width: 100%;
  height: 100%;
  background: rgba(59,71,82,.75);
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transition: .8s all;
  visibility: hidden;
  opacity: 0;
}
.modal-download.visible {
  visibility: visible;
  opacity: 1;
}
.modal-download-content {
  width: 90%;
  max-width: 500px;
  animation: modalOut .8s forwards;
}
.modal-download-content.visible {
  animation: modalIn .8s forwards;
}
.modal-download-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.modal-bownload-close {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: transparent;
  background: #fbfcfc;
  font-size: 30px;
  line-height: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: -moz-linear-gradient(top,  #fbfcfc 9%, #cccccc 100%);
  background: -webkit-linear-gradient(top,  #fbfcfc 9%,#cccccc 100%);
  background: linear-gradient(to bottom,  #fbfcfc 9%,#cccccc 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fbfcfc', endColorstr='#cccccc',GradientType=0 );
}
.modal-download-box {
  width: 100%;
  padding: 20px;
  border-radius: 20px;
}
.modal-download-box-form input, .modal-download-box-form textarea {
  width: 100%;
  padding: 4px 10px;
  margin-bottom: 5px;
  border: 2px solid #CCCCCC;
}
.modal-download-form-menu {
  margin-top: 15px;
}
.modal-download-form-menu button {
  width: 80px;
  height: 30px;
  margin-right: 10px;
  border: 2px solid #CCCCCC;
  padding: 0;
}
.modal-download-form-menu button:first-child {
  margin-right: 10px;
  background: #5E959C;
  border: 2px solid #5E959C;
  color: #ffffff;
}
.modal-download-message {
  font-size: 14px;
  line-height: 16px;
  padding: 10px 0 0;
  word-wrap: break-word;
}
.private {
  width: 100%;
  display: flex;
}
.private .modal-download-box-form {
  width: 60% !important;
  padding-right: 20px;
}
.private-message {
  width: 40%;
  padding-left: 20px;
  border-left: 2px solid #CCCCCC;
}
.private-message h4 {
  color: #8C8536;
}
@keyframes modalIn {
	0% {
		transform: translateY(-3000px);
	}
	60% {
		transform: translateY(20px);
	}
	85% {
		transform: translateY(-5px);
	}
	100% {
		transform: translateY(0);
	}
}
@keyframes modalOut {
	0% {
		transform: translateY(0);
	}
	60% {
		transform: translateY(20px);
	}
	100% {
		transform: translateY(-3000px);
	}
}
@media screen and (max-width: 550px) {
  .modal-download {
    align-items: flex-start;
    padding: 20px 0;
    overflow: auto;
  }
  .modal-download-form {
    flex-direction: column;
  }
  .private .modal-download-box-form {
    width: 100% !important;
    margin-bottom: 20px;
  }
  .private-message {
    width: 100%;
    border-top: 2px solid #CCCCCC;
    border-left: 0;
    padding-left: 0;
  }
}