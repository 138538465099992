@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?7lv8mh');
  src:  url('fonts/icomoon.eot?7lv8mh#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?7lv8mh') format('truetype'),
    url('fonts/icomoon.woff?7lv8mh') format('woff'),
    url('fonts/icomoon.svg?7lv8mh#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-graphic:before {
  content: "\e917";
}
.icon-plus:before {
  content: "\e918";
}
.icon-settings:before {
  content: "\e919";
}
.icon-dot:before {
  content: "\e900";
}
.icon-arrow-left:before {
  content: "\e901";
}
.icon-arrow-right:before {
  content: "\e902";
}
.icon-documents:before {
  content: "\e903";
}
.icon-download:before {
  content: "\e904";
}
.icon-ecosystem:before {
  content: "\e905";
}
.icon-file:before {
  content: "\e906";
}
.icon-hand:before {
  content: "\e907";
}
.icon-info:before {
  content: "\e908";
}
.icon-information:before {
  content: "\e909";
}
.icon-job:before {
  content: "\e90a";
}
.icon-link:before {
  content: "\e90b";
}
.icon-map:before {
  content: "\e90c";
}
.icon-menu:before {
  content: "\e90d";
}
.icon-more:before {
  content: "\e90e";
}
.icon-news:before {
  content: "\e90f";
}
.icon-newsletter:before {
  content: "\e910";
}
.icon-next:before {
  content: "\e911";
}
.icon-prev:before {
  content: "\e912";
}
.icon-search:before {
  content: "\e913";
}
.icon-selected:before {
  content: "\e914";
}
.icon-tools:before {
  content: "\e915";
}
.icon-view:before {
  content: "\e916";
}
