.ecosystems-container {
  width: 100%;
  padding-bottom: 30px;
  margin-bottom: 20px;
  border-bottom: 4px solid #3b4752;
}
.ecosystems-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.ecosystems-box-stages {
  width: calc(100% - 330px);
}
.ecosystems-box-count {
  display: flex;
}
.ecosystems-charts {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.ecosystems-sections {
  width: 60%;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.ecosystems-sections-content {
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 3px;
}
.ecosystems-count {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  display: block;
}
.ecosystems-count-container {
  display: flex;
  max-width: 350px;
  padding-bottom: 20px;
  border: 1px solid #cccccc;
  border-radius: 3px;
}
.ecosystems-count-item {
  flex: 1;
}
.ecosystems-count-item:first-child .ecosystems-count-number {
  border-right: 1.5px solid #cccccc;
}
.ecosystems-count-item:last-child .ecosystems-count-number {
  border-left: 1.5px solid #cccccc;
}
.ecosystems-count-subtitle {
  color: #969696;
  margin-left: 10px;
}
.ecosystems-count-content {
  width: 100%;
  height: 100%;
}
.ecosystems-count-number {
  width: 100%;
  background: #f59c00;
  color: #ffffff;
  font-size: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ecosystem-population-content {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
}
.ecosystem-population-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.ecosystem-population-bars {
  width: 100%;
}
.ecosystem-population-chart {
  width: 40%;
}
.ecosystem-title {
  display: flex;
  align-items: center;
  color: #f59c00;
  font-size: 24px;
  margin-bottom: 10px;
}
.ecosystem-title span {
  margin-right: 5px;
}
.ecosystem-population-bars .bar-horizontal-bar-content {
  width: calc(100% - 20px);
}
@media screen and (max-width: 1000px) {
  .ecosystems-content {
    flex-wrap: wrap;
  }
  .territory-cities {
    width: 100%;
    margin: 0 auto 20px;
  }
  .ecosystems-box-stages {
    width: 100%;
  }
}
@media screen and (max-width: 580px) {
  .ecosystem-population-bars .bar-horizontal-bar-content {
    width: calc(55% - 20px);
  }
}
